<template>
  <v-container class="table-filters" fluid>
    <v-row>
      <v-col cols="12" sm="3">
        <calendar-range-input
          v-model="selectedFiltersCreatedDateRange"
          only-past-dates
          :label="$trans('created_date_range')"
          clearable
          dense
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedNotificationTypes"
          :items="notificationTypesInputValues"
          :label="$trans('notification_type_title')"
          multiple
          small-chips
          outlined
          hide-details
          clearable
          deletable-chips
          dense
          :disabled="disabled"
          :menu-props="{ offsetY: true }"
        >
          <template #item="data">
            <div class="d-flex align-center pointer my-2">
              <span class="text-break cd-v-select-line-height">{{
                data.item.text
              }}</span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedNotificationCategories"
          :items="notificationCategoriesInputValues"
          :label="$trans('booking_reminders_element_type_label')"
          multiple
          small-chips
          outlined
          hide-details
          clearable
          deletable-chips
          dense
          :disabled="disabled"
          :menu-props="{ offsetY: true }"
        >
          <template #item="data">
            <div class="d-flex align-center pointer my-2">
              <span class="text-break cd-v-select-line-height">{{
                data.item.text
              }}</span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedFiltersStatuses"
          :items="notificationStatusesInputValues"
          :label="$trans('status')"
          deletable-chips
          dense
          hide-details="auto"
          multiple
          outlined
          small-chips
          :menu-props="{ offsetY: true }"
          clearable
          :disabled="disabled"
        >
          <template #item="data">
            <div class="d-flex align-center pointer my-2">
              <span class="text-break cd-v-select-line-height">{{
                data.item.text
              }}</span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <clients-field
          v-model="selectedFiltersUsers"
          hide-actions
          multiple
          small-chips
          deletable-chips
          dense
          :disabled="disabled"
          keep-menu-open-on-select
        />
      </v-col>
      <v-col cols="12" sm="3">
        <employee-field
          v-model="selectedFiltersEmployees"
          :label="$trans('employee')"
          dense
          :disabled="disabled"
          small-chips
          multiple
          deletable-chips
          keep-menu-open-on-select
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="selectedFiltersSearch"
          :label="$trans('search')"
          :placeholder="$trans('autocomplete_hint')"
          append-icon="$magnify"
          hide-details
          outlined
          single-line
          clearable
          :disabled="disabled"
          dense
        />
      </v-col>
    </v-row>
    <v-row class="my-4" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12" md="3" sm="6">
        <v-btn
          color="primary"
          outlined
          small
          :disabled="disabled"
          @click="clearFilters"
        >
          {{ $trans("clear_filters") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedListFilters from "@/calendesk/mixins/sharedListFilters";
import CalendarRangeInput from "@/lib/calendesk-js-library/components/CalendarRangeInput.vue";
import { notificationCategories } from "@/calendesk/prototypes/notificationCategories";
import { notificationTypes } from "@/lib/calendesk-js-library/notificationTypes";
import { notificationStatuses } from "@/calendesk/prototypes/notificationStatuses";
import ClientsField from "@/views/dashboard/pages/Calendar/components/forms/Booking/ClientsField.vue";
import EmployeeField from "@/views/dashboard/pages/Calendar/components/forms/Booking/EmployeeField.vue";

export default {
  name: "CustomerNotificationsFilter",
  components: { EmployeeField, ClientsField, CalendarRangeInput },
  mixins: [sharedListFilters],
  data() {
    return {
      cacheId: "newsletter-list-filters",
      selectedFiltersSearch: null,
      selectedFiltersUsers: null,
      selectedFiltersEmployees: null,
      selectedFiltersCreatedDateRange: null,
      selectedNotificationTypes: null,
      selectedNotificationCategories: null,
      selectedFiltersStatuses: null,
      notificationCategoriesInputValues: notificationCategories.inputValues(),
      notificationTypesInputValues: notificationTypes.inputValues(),
      notificationStatusesInputValues: notificationStatuses.inputValues(),
    };
  },
  computed: {
    filters() {
      return {
        selectedFiltersSearch: this.selectedFiltersSearch,
        selectedFiltersCreatedDateRange: this.selectedFiltersCreatedDateRange,
        selectedFiltersUsers: this.selectedFiltersUsers,
        selectedFiltersEmployees: this.selectedFiltersEmployees,
        selectedNotificationTypes: this.selectedNotificationTypes,
        selectedNotificationCategories: this.selectedNotificationCategories,
        selectedFiltersStatuses: this.selectedFiltersStatuses,
      };
    },
  },
  methods: {
    getData() {
      const data = {};

      if (this.selectedFiltersSearch) {
        data.query = this.selectedFiltersSearch;
      }

      if (
        this.selectedFiltersCreatedDateRange &&
        this.selectedFiltersCreatedDateRange.length > 0
      ) {
        data.created_from = this.selectedFiltersCreatedDateRange[0];

        if (this.selectedFiltersCreatedDateRange.length === 2) {
          data.created_to = this.selectedFiltersCreatedDateRange[1];
        } else {
          data.created_to = data.created_from;
        }
      }

      if (this.selectedFiltersUsers && this.selectedFiltersUsers.length > 0) {
        data.user_ids = this.selectedFiltersUsers.map((u) => u.id).join(",");
      } else if (this.selectedFiltersUsers && this.selectedFiltersUsers.id) {
        data.user_ids = this.selectedFiltersUsers.id;
      }

      if (
        this.selectedFiltersEmployees &&
        this.selectedFiltersEmployees.length > 0
      ) {
        data.employee_ids = this.selectedFiltersEmployees
          .map((u) => u.id)
          .join(",");
      } else if (
        this.selectedFiltersEmployees &&
        this.selectedFiltersEmployees.id
      ) {
        data.employee_ids = this.selectedFiltersEmployees.id;
      }

      if (
        this.selectedNotificationTypes &&
        this.selectedNotificationTypes.length > 0
      ) {
        data.notification_type_ids = this.selectedNotificationTypes.join(",");
      }

      if (
        this.selectedNotificationCategories &&
        this.selectedNotificationCategories.length > 0
      ) {
        data.notification_category_ids =
          this.selectedNotificationCategories.join(",");
      }

      if (
        this.selectedFiltersStatuses &&
        this.selectedFiltersStatuses.length > 0
      ) {
        data.status_ids = this.selectedFiltersStatuses.join(",");
      }

      return data;
    },
  },
};
</script>
