import { trans } from "@/lib/calendesk-js-library/prototypes/trans";

export const notificationStatuses = {
  unknown: 0,
  sent: 1,
  delivered: 2,
  not_delivered: 3,
  error: 4,

  inputValues() {
    return [
      {
        text: trans("notification_status_0"),
        value: notificationStatuses.unknown,
      },
      {
        text: trans("notification_status_1"),
        value: notificationStatuses.sent,
      },
      {
        text: trans("notification_status_2"),
        value: notificationStatuses.delivered,
      },
      {
        text: trans("notification_status_3"),
        value: notificationStatuses.not_delivered,
      },
      {
        text: trans("notification_status_4"),
        value: notificationStatuses.error,
      },
    ];
  },
};
