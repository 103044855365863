<template>
  <tr
    :key="item.id"
    :class="{
      'v-data-table__mobile-table-row': !$vuetify.breakpoint.mdAndUp,
    }"
    class="table-row"
    @click.stop="clickRow(item)"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <td
      class="pt-2 text-center"
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        <div>
          {{ $trans("id") }}
        </div>
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="text-left">
          <div>{{ item.id }}</div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("customer_type") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div v-if="item.recipients && item.recipients.length === 1">
          <a
            v-if="
              item.customer_type ===
                $helpers.customerNotificationCustomerType.user ||
              item.customer_type ===
                $helpers.customerNotificationCustomerType.employee
            "
            @click.stop="
              item.recipients[0].user_id || item.recipients[0].employee_id
                ? item.customer_type ===
                  $helpers.customerNotificationCustomerType.user
                  ? goToUser({ id: item.recipients[0].user_id })
                  : goToEmployee({ id: item.recipients[0].employee_id })
                : null
            "
          >
            <span>
              {{ $trans("customer_type_" + item.customer_type) }}
            </span>
            <template
              v-if="
                item.recipients[0].user_id || item.recipients[0].employee_id
              "
            >
              ({{ $trans("id") }}:
              <template
                v-if="
                  item.customer_type ===
                  $helpers.customerNotificationCustomerType.user
                "
                >{{ item.recipients[0].user_id }}</template
              >
              <template
                v-if="
                  item.customer_type ===
                  $helpers.customerNotificationCustomerType.employee
                "
                >{{ item.recipients[0].employee_id }}</template
              >)
            </template>
          </a>
          <span v-else>{{
            $trans("customer_type_" + item.customer_type)
          }}</span>
        </div>
        <div v-else>
          {{ $trans("recipients_count_title") }}: {{ item.recipients_count }}
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("status") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div
          v-if="
            item.recipients && item.recipients.length === 1 && getLastStatus
          "
          class="d-flex align-center"
        >
          <v-tooltip v-if="showErrorDetailsForStatus" bottom>
            <template #activator="{ on }">
              <div v-on="on">
                <v-icon color="error" left>$alert</v-icon>
              </div>
            </template>
            {{ getLastStatus.details }}
          </v-tooltip>
          {{ $trans("notification_status_" + getLastStatus.status) }}
        </div>
        <div v-else>
          {{ $trans("notification_status_1") }}
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__20-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("notification_type_title") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
        class="d-flex align-center"
      >
        <v-icon color="green" left>
          {{ getIconForNotificationType(item.notification_type) }}
        </v-icon>
        <div>
          {{ $trans("notification_type_" + item.notification_type) }}<br />
          {{ $trans("notification_category_" + item.notification_category) }}
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__30-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("preview") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="text-wrap break-word-normal">
          {{ item.preview | stripHtml }}
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("usage") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="text-center">
          {{ fullUsage || "-" }}
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("created_at") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <span>{{
          item.created_at | dateTime($helpers.hourDayMonthAndYearDate)
        }}</span>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__manage-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div class="row-list-buttons white rounded mr-1 mt-2">
        <customer-notification-list-buttons
          :is-hovered="isHovered"
          :notification="item"
        />
      </div>
    </td>
  </tr>
</template>
<script>
import CustomerNotificationListButtons from "@/views/dashboard/pages/Notifications/CustomerNotifications/components/CustomerNotificationListButtons.vue";
import itemRowActions from "@/calendesk/mixins/itemRowActions";
import {
  goToEmployee,
  goToUser,
} from "@/lib/calendesk-js-library/tools/helpers";
import { notificationStatuses } from "@/calendesk/prototypes/notificationStatuses";

export default {
  components: {
    CustomerNotificationListButtons,
  },
  mixins: [itemRowActions],
  computed: {
    fullUsage() {
      if (this.item.recipients && this.item.recipients.length > 0) {
        return this.item.recipients.reduce((count, recipient) => {
          return recipient.usage ? count + 1 : count;
        }, 0);
      }
      return 0;
    },
    getLastStatus() {
      if (
        this.item.recipients &&
        this.item.recipients.length > 0 &&
        this.item.recipients[0].statuses &&
        this.item.recipients[0].statuses.length > 0
      ) {
        return this.item.recipients[0].statuses[
          this.item.recipients[0].statuses.length - 1
        ];
      }

      return null;
    },
    showErrorDetailsForStatus() {
      return (
        this.getLastStatus.status === notificationStatuses.error &&
        !!this.getLastStatus.details
      );
    },
  },
  methods: {
    goToEmployee,
    goToUser,
    getIconForNotificationType(notificationType) {
      if (notificationType === this.$helpers.customerNotificationType.email) {
        return "$email";
      }
      if (notificationType === this.$helpers.customerNotificationType.sms) {
        return "$cell-phone-message";
      }
      if (notificationType === this.$helpers.customerNotificationType.push) {
        return "$smartphone";
      }

      return "";
    },
  },
};
</script>
